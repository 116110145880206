<template>
  <div class="cards-view"><Cards /></div>
</template>
<script>
const Cards = () => import("@/components/Cards.vue");
export default {
  name: "Cards-view",

  components: {
    Cards
  }
};
</script>
