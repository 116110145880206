<template>
  <div class="about">
    <Items />
  </div>
</template>
<script>
const Items = () => import("@/components/Items.vue");
export default {
  name: "Home",

  components: {
    Items
  }
};
</script>
